<template>
    <div>
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">{{ title }}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body p-0">
                    <div v-for="(bar, index) in operations" class="row mb-1" :key="index">
                        <h5 class="col-sm-2">{{ bar.title }}:</h5>

                        <div class="col-sm-8 pt-1">
                            <b-progress height="1.2rem" :variant="bar.variant">
                                <b-progress-bar :value="bar.value">
                                    <span v-if="bar.status == 2 || bar.status == 3"><strong>{{ bar.value }}%</strong></span>
                                </b-progress-bar>
                            </b-progress>

                        </div>
                        <div class="col-sm-2 text-center">
                            <button v-if="btnActionStatus(bar.status) == 'start'" class="btn btn-primary btn-sm mr-2 ml-2" @click="changeStatus({id: bar.id, action: 1})">{{$t('start')}}</button>
                            <button v-if="bar.status == 5" class="btn btn-primary btn-sm mr-2 ml-2" @click="changeStatus({id: bar.id, action: 1})">{{$t('restart')}}</button>
                            <button v-if="bar.status == 2" class="btn btn-dark btn-sm mr-2 ml-2" @click="changeStatus({id: bar.id, action: 2})">{{$t('complete')}}</button>
                            <button v-if="btnActionStatus(bar.status) == 'stop'" class="btn btn-danger btn-sm mr-2 ml-2" @click="changeStatus({id: bar.id, action: 3})">{{$t('stop')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "operations-work-order",
        data(){
            return{
                mainRoute: 'manufacturers/work_orders',
                routeChangeStatus: 'manufacturers/work_order/toggle_action',


                work_order_id: this.$route.params.id ? this.$route.params.id : null,
                title: null,
                operations:[
                    // { title: 'operation 1', variant: 'secondary', value: 0 },
                    // { title: 'operation 2', variant: 'primary', value: 75 },
                    // { title: 'operation 3', variant: 'danger', value: 75 },
                ],
                start_date_time: null,
                expected_delivery_date_time: null,
            }
        },
        methods:{
            async getData() {
                ApiService.get(`${this.mainRoute}/${this.work_order_id}`).then((response) => {

                    this.title = response.data.data.title;
                    this.start_date_time = response.data.data.start_date_time;
                    this.expected_delivery_date_time = response.data.data.expected_delivery_date_time;

                    this.operations = response.data.data.operations.map((row)=>{

                        let _progress_val = 0;
                        if (row.start_date){
                            let _operation_time = (row.operation_time ? parseInt(row.operation_time) : 0);

                            let startTime = this.$moment(response.data.data.date_time_now, "YYYY-MM-DD hh:mm:ss");
                            let endTime = this.$moment(row.start_date, "YYYY-MM-DD hh:mm:ss")
                                .add(_operation_time, 'minutes');

                            let _time_in_minutes = endTime.diff(startTime, 'minutes');
                            _progress_val = (100 - ((_time_in_minutes*100)/ _operation_time)).toFixed(1);

                            if (_progress_val > 100 || [3,4,5].includes(row.status))
                                _progress_val = 100;
                        }else if ([3,4,5].includes(row.status)){
                            _progress_val = 100;
                        }



                        let _variant = '';
                        // 1=>pending,2=>in_progress,3=>confirm,4=>canceled,5=>stop
                        switch (row.status) {
                            case 1:
                                _variant = 'secondary';
                                break;
                            case 2:
                                _variant = 'warning';
                                break;
                            case 3:
                                _variant = 'primary';
                                break;
                            case 4:
                            case 5:
                                _variant = 'danger';
                                break;
                            default:
                                _variant = 'secondary';
                                break;

                        }
                        return {
                            id: row.id,
                            title: row.operation.name,
                            variant: _variant,
                            value: _progress_val,
                            status: row.status,

                        }
                    });



                });
            },

            btnActionStatus(status){
                // 1=>pending,2=>in_progress,3=>confirm,4=>canceled,5=>stop
                if ([1,4].includes(status)){
                    return 'start';
                }else if([1,2].includes(status)) {
                    return 'stop';
                }
            },
            getFetch() {
                this.getData()
            },

            changeStatus(item) {
                let _msg = (item.action == 3? this.$t('Do_you_want_stop_this_operation')  : this.$t('Do_you_want_start_this_operation'))
                this.$confirmAlert(_msg, this.actionChangeStatus, item);
            },

            actionChangeStatus(item) {
                ApiService.patch(`${this.routeChangeStatus}/${item.id}`,{
                    action: item.action
                }).then(() => {
                    this.getFetch();
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.work_order"), route: '/manufactories/work-order'}, {title: this.$t('work_order.operations')}]);
            this.getData();
        }
    }
</script>

<style scoped>

</style>